var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-card",
              { staticClass: "mb-1", attrs: { "no-body": "" } },
              [
                _c(
                  "b-card-body",
                  { staticClass: "shadow-lg" },
                  [
                    _c("dataset", {
                      attrs: { "ds-data": _vm.data },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var ds = ref.ds
                            return [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-md-4 mb-3" },
                                  [
                                    _c("dataset-search", {
                                      attrs: {
                                        "ds-search-placeholder": "Rechercher..."
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "col-md-12" }, [
                                  _c("div", {}, [
                                    _c(
                                      "table",
                                      {
                                        staticClass:
                                          "table table-hover d-md-table"
                                      },
                                      [
                                        _c("thead", [
                                          _c(
                                            "tr",
                                            _vm._l(_vm.cols, function(th) {
                                              return _c(
                                                "th",
                                                {
                                                  key: th.field,
                                                  style:
                                                    th.field == "objet"
                                                      ? "width: 20%;"
                                                      : ""
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(th.name) + " "
                                                  )
                                                ]
                                              )
                                            }),
                                            0
                                          )
                                        ]),
                                        _c("dataset-item", {
                                          attrs: { tag: "tbody" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var row = ref.row
                                                  var rowIndex = ref.rowIndex
                                                  return [
                                                    _c("tr", [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(rowIndex + 1)
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(row.fullName)
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(
                                                            row.identitydocNo
                                                          )
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              row.hasEntered
                                                                .html
                                                            )
                                                          }
                                                        })
                                                      ]),
                                                      _c("td", [
                                                        _c("span", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              row.hasExited.html
                                                            )
                                                          }
                                                        })
                                                      ]),
                                                      _c("td", [
                                                        !row.hasEntered.plain &&
                                                        _vm.$can(
                                                          "isp_acces_log_approve_entry"
                                                        )
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-success",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.registerEntry(
                                                                      row.uuid,
                                                                      row.fullName
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Confirmer l'entrée"
                                                                )
                                                              ]
                                                            )
                                                          : _c("span", [
                                                              _vm._v("N/A")
                                                            ])
                                                      ]),
                                                      _c("td", [
                                                        !row.hasExited.plain &&
                                                        row.hasEntered.plain &&
                                                        _vm.$can(
                                                          "isp_acces_log_approve_exit"
                                                        )
                                                          ? _c(
                                                              "button",
                                                              {
                                                                staticClass:
                                                                  "btn btn-warning",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.registerExit(
                                                                      row.uuid,
                                                                      row.fullName
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Confirmer la sortie"
                                                                )
                                                              ]
                                                            )
                                                          : _c("span", [
                                                              _vm._v("N/A")
                                                            ])
                                                      ])
                                                    ])
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-md-row flex-column justify-content-between align-items-center"
                                },
                                [
                                  _c("dataset-show", {
                                    attrs: { "ds-show-entries": 5 }
                                  }),
                                  _c("dataset-pager")
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }