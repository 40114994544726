<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Gestion d'accès",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Dataset,
    DatasetItem,
    DatasetPager,
    DatasetShow,
    DatasetSearch
  },
  data() {
    return {
      title: "Gestion d'accès",
      data: [],
      items: [
        {
          text: "Catalogue des services",
          to: {name:"service_catalog"},
        },
        {
          text: "Gestion d'accès",
          active: true,
        }
      ],

      cols: [
        {
          name: "ID",
          field: "id",
          sort: "",
        },
        {
          name: "Nom complet",
          field: "fullName",
          sort: "",
        },
        {
          name: "N° Pièce d'identitée",
          field: "identitydoc_no",
          sort: "",
        },
        {
          name: "Entrée",
          field: "type",
          sort: "",
        },
        {
          name: "Sortie",
          field: "amnt",
          sort: "",
        },
        {
          name: "Confirmer l'entrée",
          field: "ddbv",
          sort: "",
        },
        {
          name: "Confirmer la sortie",
          field: "dexp",
          sort: "",
        },
      ],
    };
  },

  methods: {
    registerEntry(uid, fullName){
      Swal.fire({
        title: "Êtes-vous sûr de Confirmer l'entrée du : " + fullName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirmer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/ops/isp_log/register_entry", {
              uid: uid,
            })
            .then((res) => {
              var status = res.data.status;
              loader.hide();
              switch (status) {
                case 200:
                  this.$toast.success(res.data.msg);
                  this.fetchIspLogs();
                  break;

                case 500:
                  this.$toast.warning(res.data.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    registerExit(uid, fullName){
      Swal.fire({
        title: "Êtes-vous sûr de Confirmer la sortie du : " + fullName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Confirmer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/ops/isp_log/register_exit", {
              uid: uid,
            })
            .then((res) => {
              var status = res.data.status;
              loader.hide();
              switch (status) {
                case 200:
                  this.$toast.success(res.data.msg);
                  this.fetchIspLogs();
                  break;

                case 500:
                  this.$toast.warning(res.data.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    fetchIspLogs(){
      this.$http
        .post("/ops/isp_log/list")
        .then((res) => {
          var status = res.data.status;
          switch (status) {
            case 200:
              this.data = res.data.list;
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
  },

  mounted() {
    this.fetchIspLogs();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <b-card no-body class="mb-1">
          <b-card-body class="shadow-lg">
            <dataset v-slot="{ ds }" :ds-data="data">

              <div class="row">
                <div class="col-md-4 mb-3">
                  <dataset-search ds-search-placeholder="Rechercher..." />
                </div>
                <div class="col-md-12">
                  <div class="">
                    <table class="table table-hover d-md-table">
                      <thead>
                        <tr>
                          <th
                            v-for="th in cols"
                            :style="th.field == 'objet' ? 'width: 20%;' : ''"
                            :key="th.field"
                          >
                            {{ th.name }}
                          </th>
                        </tr>
                      </thead>
                      <dataset-item tag="tbody">
                        <template #default="{ row, rowIndex }">
                          <tr>
                            <td>{{ rowIndex + 1 }}</td>
                            <td>{{ row.fullName }}</td>
                            <td>{{ row.identitydocNo }}</td>
                            <td><span v-html="row.hasEntered.html"></span></td>
                            <td><span v-html="row.hasExited.html"></span></td>
                            <td> <button v-if="!row.hasEntered.plain && $can('isp_acces_log_approve_entry')" class="btn btn-success" @click="registerEntry(row.uuid, row.fullName)">Confirmer l'entrée</button> <span v-else>N/A</span> </td>
                            <td> <button v-if="!row.hasExited.plain && row.hasEntered.plain && $can('isp_acces_log_approve_exit')" class="btn btn-warning" @click="registerExit(row.uuid, row.fullName)">Confirmer la sortie</button> <span v-else>N/A</span> </td>
                          </tr>
                        </template>
                      </dataset-item>
                    </table>
                  </div>
                </div>
              </div>
              

              <div
                class="d-flex flex-md-row flex-column justify-content-between align-items-center"
              >
                <!-- <dataset-info class="mb-2 mb-md-0" /> -->

                <dataset-show :ds-show-entries="5" />
                <dataset-pager />
              </div>
            </dataset>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </Layout>
</template>
